import _ from 'lodash'
import React from 'react'
import {
  Cta, SliderEvents, Spinner, Navbar,
} from '../../components'
import ContainerExperiences from './components/container-experiences'
import * as styles from './experiences-style.module.scss'
import Seo from '../../components/Seo'

const ExperiencesView = ({
  loading,
  experiences,
  eventData,
  location,
  t,
}) => {
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <div className={styles.experiences__container}>
      <Seo title="Kamo - Experience" yoast={_.get(experiences, "yoast_head_json")} />
      <div className={styles.experiences__box}>
        <div className={styles.experiences__title}>
          <h2>{t('experiences.title')}</h2>
          <p>{t('experiences.subtitle')}</p>
        </div>
        <Navbar
          title={t('menu.bookWith')}
          loading={loading}
          t={t}
        />
        <ContainerExperiences
          data={_.get(experiences, 'acf.experiences')}
          location={location}
        />
        <div>
        <SliderEvents eventData={eventData} />
        </div>
        <div>
          <Cta />
        </div>
      </div>
    </div>

  )
}

export default ExperiencesView
