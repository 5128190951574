import _ from 'lodash'
import classNames from 'classnames'
import React, { useRef, useEffect } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useScrollTo } from 'react-use-window-scroll'
import { slugify } from '../../../../helpers'
import './block-experiences-style.scss'

const BlockExperiences = ({ data, hash }) => {
  const scrollTo = useScrollTo()
  const containerExperienceRef = useRef(null)
  const breakpoints = useBreakpoint()
  const position = _.get(data, 'alignment.value')

  useEffect(() => {
    if (slugify(_.get(data, 'title'), '-') === hash.substring(1, hash.length)) {
      scrollTo(containerExperienceRef.current.offsetTop, 0)
    }
  }, [hash, data])

  const containerClass = classNames({
    blockExperiences__container: true,
    is_top: position === 'text_up',
    is_bottom: position === 'text_down',
    is_landscape: position === 'landscape',
  })
  return (
    <div
      ref={containerExperienceRef}
      className={containerClass}
      id={slugify(_.get(data, 'title'), '-')}
    >
      <div className="blockExperiences__content">
        <div>
          <div className="blockExperiences__content__text">
            <h2 >
              {_.get(data, 'title')}
            </h2>
            <p >
              {_.get(data, 'description')}
            </p>

          </div>

          <div className="blockExperiences__content__image"
          >
            <img src={_.get(data, 'images.content_image.url')} alt={_.get(data, 'images.content_image.alt')}/>
          </div>
        </div>

      </div>
      <div className="blockExperiences__image">
        <div className="blockExperiences__image__box">
          <img
            src={
              !!breakpoints.sm && position === 'landscape'
                ? _.get(data, 'images.content_image.url')
                : _.get(data, 'images.home_image.url')}
            alt={_.get(data, 'images.home_image.alt')}
          />
        </div>

      </div>
    </div>
  )
}

export default BlockExperiences
