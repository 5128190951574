import _ from 'lodash'
import React from 'react'
import BlockExperiences from '../block-experiences'
import * as styles from '../../experiences-style.module.scss'

const ContainerExperiences = ({ data, location }) => {
  const { hash } = location
  return (

    <div className={styles.experiences__content}>
      {_.map(data, (ex, idx) => (
        <BlockExperiences
          key={idx}
          data={ex}
          hash={hash}
        />
      ))}

    </div>

  )
}

export default ContainerExperiences
