import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import ExperiencesView from './experiences-view'

const ExperiencesController = (props) => {
  const [experiences, setExperiences] = useState([])
  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState([]); // State for event data
  const { location } = props
  const { t, i18n } = useTranslation()

  const getExperienceData = useCallback(async () => {
    setLoading(true)
    // if (typeof window !== 'undefined') {
    //   window.scrollTo(0, 0)
    // }

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
        slug: 'experience-kyoto',
      } : {
        slug: 'experience-kyoto',
      },
    }).then((resp) => {
      setExperiences(resp[0])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getExperienceData()
  }, [])

  const getEventData = useCallback(async () => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
    };

    makeRequest({
      headers,
      endPoint: 'event', // Modify to match your API endpoint for events
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
      } : {},
    }).then((resp) => {
      setEventData(resp);
      setLoading(false);
    });
  }, [i18n.language]);

  useEffect(() => {
    getEventData(); // Fetch event data when the language changes
  }, [i18n.language]);

  const viewProps = {
    loading,
    t,
    experiences,
    eventData,
    location,

  }
  return (
    <ExperiencesView {...viewProps} />

  )
}

export default ExperiencesController
